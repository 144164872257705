(function ($) {
  $.fn.existsCall = function (callback) {
    var args = [].slice.call(arguments, 1);
    if (this.length) {
      callback.call(this, args);
    }
    return this;
  };

  jQuery.fn.exists = function () {
    return this.length > 0;
  };

  function listHeaders(tab) {
    $(tab)
      .find("h2")
      .each(function () {
        var link = $(this).text();
        var newLink = link
          .replace(/\s+/g, "")
          .replace(/\?/g, "")
          .replace(/\&/g, "")
          .replace(/\(/g, "")
          .replace(/\)/g, "");
        $("ul#scroller").append($("<li/>", { text: link }));
        $(this).attr("id", newLink);
      });

    $("ul#scroller li").each(function () {
      var ID = $(this).text();
      var newID = ID.replace(/\s+/g, "")
        .replace(/\?/g, "")
        .replace(/\&/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "");
      $(this).wrapInner('<a href="#' + newID + '" />');
    });
  }

  function linkAnimate() {
    $("#scroller li a[href^='#']").on("click", function (e) {
      e.preventDefault();

      var hash = this.hash;

      $("html, body").animate(
        {
          scrollTop: $(hash).offset().top,
        },
        500,
        function () {
          window.location.hash = hash;
        }
      );
    });
  }

  $(document).ready(function () {
    // $('.navbar').addClass('meganav');
    // $('.meganav .dropdown-menu .dropdown-menu').parent().addClass('has-children').parents('li').addClass('dropdown mega-menu');
    $('[data-toggle="offcanvas"]').click(function () {
      $(".row-offcanvas").toggleClass("active");
    });
    var body = $(document.body);

    body.scrollspy({
      target: "#scrollSpy",
    });

    $(".gallery-item a").swipebox({
      hideBarsDelay: 0,
    });

    $("#searchToggle").on("click", function (e) {
      $(".top-search").toggleClass("active");
      e.preventDefault();
    });

    $("#searchClose").on("click", function (e) {
      $(".top-search").toggleClass("active");
      e.preventDefault();
    });

    $("#navFix").affix({
      offset: {
        top: function () {
          return $(".top-bar-bg").outerHeight();
        },
      },
    });

    $("#navFix").on("affix.bs.affix", function () {
      $("#mainCarousel").css("margin-top", "50px");
    });

    $("#navFix").on("affix-top.bs.affix", function () {
      $("#mainCarousel").css("margin-top", "0");
    });

    $('.page-template-template-product a[data-toggle="tab"]').on(
      "shown.bs.tab",
      function (e) {
        var tab = $(e.target).attr("href");
        $("ul#scroller").html("");
        listHeaders(tab);
        linkAnimate();
        $(document.body).scrollspy("refresh");
      }
    );

    var contentHeight = $("main.product").innerHeight();
    var prodAffixHeight = $(".prod-affix").innerHeight();
    console.log(prodAffixHeight);
    if (prodAffixHeight < contentHeight) {
      $(".prod-affix").affix({
        offset: {
          top: function () {
            return $("header.banner").outerHeight(true) - 20;
          },
          bottom: function () {
            return $("footer.content-info").outerHeight(true) + 50;
          },
        },
      });
    }

    $("ul#scroller").existsCall(function () {
      var tab = $(".tab-pane.active");
      var content = $(".page-template-template-product main");
      if ($(tab).exists()) {
        listHeaders(tab);
      } else {
        listHeaders(content);
      }

      linkAnimate();

      $(".side-affix").affix({
        offset: {
          top: function () {
            return $("header.banner").outerHeight(true) - 20;
          },
          bottom: function () {
            return $("footer.content-info").outerHeight(true) + 50;
          },
        },
      });
    });
    $(".owl-carousel").owlCarousel({
      loop: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 2,
          nav: true,
          navText: [
            "<i class='fa fa-chevron-left fa-lg'></i>",
            "<i class='fa fa-chevron-right fa-lg'></i>",
          ],
          dots: false,
          dotsEach: 3,
          autoplay: true,
          autoplayTimeout: 8000,
          autoplayHoverPause: true,
        },
        600: {
          items: 4,
          nav: true,
          navText: [
            "<i class='fa fa-chevron-left fa-lg'></i>",
            "<i class='fa fa-chevron-right fa-lg'></i>",
          ],
          dots: false,
          dotsEach: 4,
          autoplay: true,
          autoplayTimeout: 8000,
          autoplayHoverPause: true,
        },
        1000: {
          items: 6,
          nav: true,
          navText: [
            "<i class='fa fa-chevron-left fa-lg'></i>",
            "<i class='fa fa-chevron-right fa-lg'></i>",
          ],
          dots: false,
          dotsEach: 6,
          loop: true,
          autoplay: true,
          autoplayTimeout: 8000,
          autoplayHoverPause: true,
        },
      },
    });

    $(".owl-carousel-arrows .next").click(function () {
      owl.trigger("owl.next");
    });

    $(".owl-carousel-arrows .prev").click(function () {
      owl.trigger("owl.prev");
    });
  });
})(jQuery); // Fully reference jQuery after this point.
